import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"

import { Content } from "page_components/not-found"

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      mainBackground={
        data?.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.publicURL
      }
      isHeaderTransparent
    >
      <Content />
    </Layout>
  )
}

export const Head = () => <Seo title="404" />

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzE5Ng==" } }) {
      nodes {
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
